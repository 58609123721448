import React from 'react';
import '../styles/comingSoon.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon">
                <h2>coming soon</h2>
            </div>
        </div>
    );
};

export default ComingSoon;