// src/pages/HomePage.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MainVisualSection from '../components/MainVisualSection';
import ComingSoon from '../components/ComingSoon';
import '../styles/homePage.css';

const HomePage = () => {
    return (
        <div className="homepage-container">
            {/* Top Third - Empty */}
            <div className="top-third"></div>

            {/* Middle Third - Main Visual Section */}
            <div className="middle-third">
                <MainVisualSection />
            </div>

            {/* Bottom Third - Coming Soon */}
            <div className="bottom-third">
                {/* <ComingSoon /> */}
            </div>
        </div>
    );
};

export default HomePage;