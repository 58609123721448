import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faHammer, faRocket } from '@fortawesome/free-solid-svg-icons';
import '../styles/mainVisualSection.css';
import ComingSoon from './ComingSoon'; // Import the Coming Soon component

const MainVisualSection = () => {
    const [hoveredWord, setHoveredWord] = useState(null);

    const words = ['design', 'build', 'deploy'];
    let currentIndex = 0;

    const getIcon = () => {
        switch (hoveredWord) {
            case 'design': return faPencilRuler;
            case 'build': return faHammer;
            case 'deploy': return faRocket;
            default: return null;
        }
    };

    const getColor = () => {
        switch (hoveredWord) {
            case 'design': return '#FFD700';
            case 'build': return '#1E90FF';
            case 'deploy': return '#32CD32';
            default: return '#ffffff';
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % words.length;
            setHoveredWord(words[currentIndex]);
        }, 800);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="outer-container">
            <div className="rounded-border-container">
                <div className="text-center">
                    <div className="icon-container" style={{ color: getColor() }}>
                        {hoveredWord && <FontAwesomeIcon icon={getIcon()} size="3x" />}
                    </div>
                    <h1 className="display-1 main-title">siuilo</h1>
                    <div className="spanning-words">
                        <span className={`spanning-word ${hoveredWord === 'design' ? 'active' : 'inactive'}`}>
                            {hoveredWord === 'design' ? 'design' : ''}
                        </span>
                        <span className={`spanning-word ${hoveredWord === 'build' ? 'active' : 'inactive'}`}>
                            {hoveredWord === 'build' ? 'build' : ''}
                        </span>
                        <span className={`spanning-word ${hoveredWord === 'deploy' ? 'active' : 'inactive'}`}>
                            {hoveredWord === 'deploy' ? 'deploy' : ''}
                        </span>
                    </div>
                </div>
            </div>
            {/* Add the ComingSoon component */}
            {/* <ComingSoon /> */}
        </div>
    );
};

export default MainVisualSection;